<template>
  <div>
    <!-- 封面图 -->
    <div class="banner" :style="postCover">
    </div>
    <!-- 内容 -->
    <v-row>
      <el-card class="edit-card">
        <div class="title">{{ this.$route.name }}</div>
        <!-- 资源标题 -->
        <div class="share-title-container">
          <el-input v-model="share.title" size="medium" placeholder="输入资源标题" />
          <el-button type="danger" size="medium" class="save-btn" @click="saveshareDraft"
            v-if="share.id == null || share.status == 3">
            保存草稿
          </el-button>
          <el-button type="danger" size="medium" @click="openModel" style="margin-left:10px">
            发布资源
          </el-button>
        </div>
        <!-- 资源内容 -->
        <mavon-editor ref="md" v-model="share.content" @imgAdd="uploadImg" style="height:calc(100vh - 260px)" />
        <!-- 添加资源对话框 -->
        <el-dialog :visible.sync="addOrEdit" width="40%" top="3vh">
          <div class="dialog-title-container" slot="title">
            发布资源
          </div>
          <!-- 资源数据 -->
          <el-form label-width="80px" size="medium" :model="share">

            <!-- 资源标签 -->

            <el-form-item label="访问类型">
              <el-select v-model="share.type" placeholder="请选择类型">
                <el-option v-for="item in typeList" :key="item.type" :label="item.desc" :value="item.type" />
              </el-select>
            </el-form-item>
            <el-form-item label="发布形式">
              <el-radio-group v-model="share.status">
                <el-radio :label="1">发布</el-radio>
                <el-radio :label="2">未发布</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="addOrEdit = false">取 消</el-button>
            <el-button type="danger" @click="saveOrUpdateshare">
              发 表
            </el-button>
          </div>
        </el-dialog>
      </el-card>
    </v-row>

  </div>
</template>

<script>

import * as imageConversion from "image-conversion";
import Vue from "vue";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
Vue.use(mavonEditor);
export default {
  created() {
    console.log("Created");
    const path = this.$route.path;
    const arr = path.split("/");
    const id = arr[2];
    if (id) {
      this.axios.get("/api/share-resource/resource/" + id).then(({ data }) => {
        this.share = data.data;
      });
    } else {
      const share = sessionStorage.getItem("share");
      if (share) {
        this.share = JSON.parse(share);
      }
    }
  },
  destroyed() {
    //资源自动保存功能
    // this.autoSaveshare();
  },
  data: function () {
    return {
      addOrEdit: false,
      autoSave: true,
      title: "",
      typeList: [
        {
          type: 1,
          desc: "用户"
        },
        {
          type: 2,
          desc: "管理员"
        },
        {
          type: 3,
          desc: "匿名"
        }
      ],
      share: {
        id: null,
        title: "",
        content: "",
        type: 1,
        status: 1
      }
    };
  },
  methods: {
    openModel() {
      if (this.share.title.trim() == "") {
        this.$message.error("资源标题不能为空");
        return false;
      }
      if (this.share.content.trim() == "") {
        this.$message.error("资源内容不能为空");
        return false;
      }
      this.addOrEdit = true;
    },
    uploadImg(pos, file) {
      var formdata = new FormData();
      if (file.size / 1024 < this.config.UPLOAD_SIZE) {
        formdata.append("file", file);
        this.axios
          .post("/api/admin/articles/images", formdata)
          .then(({ data }) => {
            this.$refs.md.$img2Url(pos, data.data);
          });
      } else {
        // 压缩到200KB,这里的200就是要压缩的大小,可自定义
        imageConversion
          .compressAccurately(file, this.config.UPLOAD_SIZE)
          .then(res => {
            formdata.append(
              "file",
              new window.File([res], file.name, { type: file.type })
            );
            this.axios
              .post("/api/admin/articles/images", formdata)
              .then(({ data }) => {
                this.$refs.md.$img2Url(pos, data.data);
              });
          });
      }
    },
    saveshareDraft() {
      if (this.share.title.trim() == "") {
        this.$message.error("资源标题不能为空");
        return false;
      }
      if (this.share.content.trim() == "") {
        this.$message.error("资源内容不能为空");
        return false;
      }
      if (this.share.content.length > 244) {
        this.$message.error("资源内容太长");
        return false;
      }
      this.share.status = 3;
      this.axios.put("/api/share-resource/resource", this.share).then(({ data }) => {
        if (data.flag) {
          if (this.share.id === null) {
            this.$store.commit("removeTab", "发布资源");
          } else {
            this.$store.commit("removeTab", "修改资源");
          }
          sessionStorage.removeItem("share");
          this.$router.push({ path: "/post-list" });
          this.$notify.success({
            title: "成功",
            message: "保存草稿成功"
          });
        } else {
          this.$notify.error({
            title: "失败",
            message: "保存草稿失败"
          });
        }
      });

      //关闭自动保存功能
      this.autoSave = false;
    },
    saveOrUpdateshare() {
      if (this.share.title.trim() == "") {
        this.$message.error("资源标题不能为空");
        return false;
      }
      if (this.share.content.trim() == "") {
        this.$message.error("资源内容不能为空");
        return false;
      }
      if (this.share.content.length > 244) {
        this.$message.error("资源内容太长");
        return false;
      }
      this.axios.put("/api/share-resource/resource", this.share).then(({ data }) => {
        if (data.flag) {
          if (this.share.id === null) {
            this.$store.commit("removeTab", "发布资源");
          } else {
            this.$store.commit("removeTab", "修改资源");
          }
          sessionStorage.removeItem("share");
          this.$router.push({ path: "/share-list" });
          this.$notify.success({
            title: "成功",
            message: data.message
          });
        } else {
          this.$notify.error({
            title: "失败",
            message: data.message
          });
        }
        this.addOrEdit = false;
      });
      //关闭自动保存功能
      this.autoSave = false;
    },
  },
  computed: {
    articleType() {
      return function (type) {
        var tagType = "";
        var name = "";
        switch (type) {
          case 1:
            tagType = "danger";
            name = "原创";
            break;
          case 2:
            tagType = "success";
            name = "转载";
            break;
          case 3:
            tagType = "primary";
            name = "翻译";
            break;
        }
        return {
          tagType: tagType,
          name: name
        };
      };
    },
    postCover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "post") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    },
  }
};
</script>

<style scoped>
.edit-card {
  margin: 372.5px auto 40px auto !important;
  width: 90%;
  min-width: 500px;
  min-height: 66px;
}

.share-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-top: 2.25rem;
}

.save-btn {
  margin-left: 0.75rem;
  background: #fff;
  color: #f56c6c;
}

.tag-item {
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.tag-item-select {
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: not-allowed;
  color: #ccccd8 !important;
}

.category-item {
  cursor: pointer;
  padding: 0.6rem 0.5rem;
}

.category-item:hover {
  background-color: #f0f9eb;
  color: #67c23a;
}

.popover-title {
  margin-bottom: 1rem;
  text-align: center;
}

.popover-container {
  margin-top: 1rem;
  height: 260px;
  overflow-y: auto;
}
</style>
